import React from "react";
import { motion } from "framer-motion";
import LeftArrowIcon from "./LeftArrowIcon";
import RightArrowIcon from "./RightArrowIcon";

type ResultPercentagesProps = {
  isSecondary: boolean;
  malePercentage: number;
  femalePercentage: number;
  hideGenderLabels?: boolean;
};

function ResultPercentages({
  isSecondary,
  malePercentage,
  femalePercentage,
  hideGenderLabels,
}: ResultPercentagesProps) {
  return (
    <div className={`flex mb-2 uppercase ${isSecondary ? "h-1/3" : "h-full"}`}>
      {malePercentage !== 0 && (
        <motion.div
          transition={{ duration: 1 }}
          animate={{ width: `${malePercentage}%` }}
          className={`h-full overflow-hidden border-dotted  relative border rounded-xl mr-1 ${
            isSecondary ? "bg-white" : "bg-orange border-transparent"
          }`}
        >
          {malePercentage >= 15 && (
            <span
              hidden={hideGenderLabels}
              className={`absolute right-2 whitespace-nowrap text-right text-copy flex items-center ${
                isSecondary
                  ? "top-2 md:bottom-2 md:top-auto text-black"
                  : "bottom-2 md:top-2 md:bottom-auto text-white"
              }`}
            >
              <LeftArrowIcon size="sm" white={!isSecondary} />
              <span className="inline-block mt-1 ml-1">Mann</span>
            </span>
          )}
          <span
            className={`absolute left-2 text-h2 md:text-h1 ${
              isSecondary ? "-bottom-2 text-black pt-2" : "top-2 text-white"
            } ${malePercentage >= 18 ? "text-h2" : "text-h3"}`}
          >
            {malePercentage >= 10 && `${malePercentage}%`}
          </span>
        </motion.div>
      )}
      {femalePercentage !== 0 && (
        <motion.div
          transition={{ duration: 1 }}
          animate={{ width: `${femalePercentage}%` }}
          className={`h-full overflow-hidden relative border border-dotted rounded-xl ml-1 text-right ${
            isSecondary ? "bg-white" : "bg-pink border-transparent"
          }`}
        >
          {femalePercentage >= 15 && (
            <span
              hidden={hideGenderLabels}
              className={`absolute left-2 whitespace-nowrap text-left text-copy w-full flex items-center ${
                isSecondary
                  ? "top-2 md:bottom-2 md:top-auto text-black"
                  : "bottom-2 md:top-2 md:bottom-auto text-white"
              }`}
            >
              <span className="inline-block mt-1 mr-1">Frau</span>
              <RightArrowIcon size="sm" white={!isSecondary} />
            </span>
          )}

          <span
            className={`absolute right-2 text-h2 md:text-h1 ${
              isSecondary ? "-bottom-2 pt-2" : "top-2 text-white"
            } ${femalePercentage >= 18 ? "text-h2" : "text-h3"}`}
          >
            {femalePercentage >= 10 && `${femalePercentage}%`}
          </span>
        </motion.div>
      )}
    </div>
  );
}

export default ResultPercentages;
