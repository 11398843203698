import React from "react";
import { Question } from "../model/Question";
import AnswerButton from "./AnswerButton";
import NoMoreQuestionsNotice from "./NoMoreQuestionsNotice";

type DesktopQuestionCardsProps = {
  questions: Question[];
  onAnswered: (questionId: string, answer: string) => void;
  questionsAnswered: number;
};

function DesktopQuestionCards({
  questions,
  onAnswered,
  questionsAnswered,
}: DesktopQuestionCardsProps) {
  const currentQuestion = questions[questions.length - 1];

  return (
    <div className="h-full flex flex-col">
      {!currentQuestion && <NoMoreQuestionsNotice />}
      {currentQuestion && (
        <>
          <div className={"flex justify-between items-end  mb-4"}>
            <h1 className="text-h1">{currentQuestion.questionText}</h1>
            <p className="text-h2 pl-4">{questionsAnswered + 1}/10</p>
          </div>
          <div className="flex gap-4 h-full w-full items-center">
            <AnswerButton
              label="Frau"
              onClick={() => onAnswered(currentQuestion.id, "Frau")}
            />
            <AnswerButton
              label="Beide"
              onClick={() => onAnswered(currentQuestion.id, "Beide")}
            />
            <AnswerButton
              label="Mann"
              onClick={() => onAnswered(currentQuestion.id, "Mann")}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default DesktopQuestionCards;
