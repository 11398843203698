import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LeftArrowIcon from "../LeftArrowIcon";
import Footer from "../Footer";
import ScrollImage from "../ScrollImage";
import AboutVideo from "../AboutVideo";

function AboutPage() {
  useEffect(() => {
    document.getElementById("app")?.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div className="w-full p-4 relative flex flex-col">
        <div className="lg:flex ">
          <div className="lg:w-1/2">
            <a href="/" className="group">
              <span className="block text-copy lg:text-h1 text-right lg:mb-20 group-hover:text-pink">
                SIND
              </span>
              <span className="block text-copy lg:text-h1 lg:mb-32 group-hover:text-pink">
                GLEICH
              </span>
              <span className="block text-copy lg:text-h1 text-right mb-8 lg:mb-0 mr-20 group-hover:text-pink">
                DA
              </span>
            </a>
            <div className="px-6 lg:px-0 lg:pl-[10%] lg:-mt-12">
              <p className="text-h3 lg:text-h2 mb-20 lg:max-w-sm">
                Zärtlich Lügen,
                <br />
                wissend Lächeln.
                <br />
                Eine Intervention zur
                <br />
                Gleichberechtigung.
              </p>
              <p className="text-copy lg:text-h3 mb-8 lg:mb-0">
                Die feministische Intervention geht mittels interaktiven
                Datenerhebungen der Frage nach, wo Frauen im gesellschaftlichen
                Bild verschwinden.
              </p>
              <p className="text-copy lg:text-h3 mb-20">
                Ziel ist es, etablierte Repräsentationsformen in Frage zu
                stellen sowie vermittelnd und verändernd auf heteronormative
                Vorstellungen einzuwirken.
              </p>
            </div>
          </div>
          <div className="text-right mb-24 xl:mb-36 lg:w-1/2 lg:mt-20">
            <img
              className="inline-block pl-20"
              src="./img/about01.jpeg"
              alt="Ausstellungsstück: Vor dem Gesetze sind alle Staatsbürger gleich"
            />
          </div>
        </div>

        <div className="flex justify-center flex-col mb-24 xl:mb-36">
          <AboutVideo />
        </div>

        <div className="flex gap-2 flex-row pr-8 mb-40 lg:max-w-6xl">
          <img
            className="w-2/3 h-auto z-10 object-cover"
            src="./img/about02.jpeg"
            alt="Banner: Sind Gleich da"
          />
          <img
            className="w-1/3 h-full object-cover"
            src="./img/about03.jpeg"
            alt="Ausstellungsstück: Persönliche Erfahrungen mit Ungleichberechtigung"
          />
        </div>
        <div className="px-6 text-copy mb-20 lg:hidden">
          <p>
            Der thematische Schwerpunkt liegt auf der bescheidenen Konstruktion
            der Mutterrolle in unserer Gesellschaft. Diese Rolle wird mit
            interaktiven Ausstellungsstücken aufgedeckt und schafft damit eine
            Erkenntnis von „blinden Flecken“: täglichen, versteckten Handlungen
            die unsere stereotypischen Rollenbilder, den Gender Bias und somit
            unsere Zukunft beeinflussen.
          </p>
        </div>
        <div className="relative">
          <img
            className="mb-40 relative z-10 lg:max-w-[70%] lg:pl-20 ml-auto"
            src="./img/about04.jpeg"
            alt="Frau schreibt eine persönliche Erfahrung auf"
          />
          <img
            className="absolute w-full max-w-2xl rotate-45 -top-0 md:-top-[20%] -left-[45%] md:left-0"
            src="./img/Blobb.png"
            alt="Illustration: Blobb"
          />
        </div>
        <div className="lg:flex">
          <div className="hidden w-1/2 lg:block max-w-xl">
            <p className="pl-[10%] text-h3">
              Der thematische Schwerpunkt liegt auf der bescheidenen
              Konstruktion der Mutterrolle in unserer Gesellschaft. Diese Rolle
              wird mit interaktiven Ausstellungsstücken aufgedeckt und schafft
              damit eine Erkenntnis von „blinden Flecken“: täglichen,
              versteckten Handlungen die unsere stereotypischen Rollenbilder,
              den Gender Bias und somit unsere Zukunft beeinflussen.
            </p>
          </div>
          <img
            className="lg:w-1/2 lg:ml-auto lg:mt-20 lg:pl-20 mb-32"
            src="./img/about05.jpeg"
            alt=""
          />
        </div>
        <div className="lg:mt-[-20%]">
          <img className="pr-8 mb-2 lg:w-1/3" src="./img/about06.jpeg" alt="" />
          <div className="lg:flex">
            <img
              className="pr-8 mb-20 lg:w-1/3"
              src="./img/about07.jpeg"
              alt=""
            />
            <div className="px-6 mb-16 lg:w-1/2 lg:ml-auto relative">
              <p className="text-h3 mb-8">
                Schreib uns eine Mail um mehr über SIND GLEICH DA zu erfahren
                oder folge uns auf Instagram.
              </p>
              <p className="text-copy flex flex-wrap gap-4 md:gap-16">
                <a href="mailto:wir@sindgleichda.com">wir@sindgleichda.com</a>
                <span>
                  <a href="https://instagram.com/sindgleichda">@sindgleichda</a>
                </span>
              </p>
            </div>
          </div>
        </div>
        <ScrollImage />
        <img
          className="absolute w-full max-w-4xl pointer-events-none -bottom-[6%] -left-[35%] md:-left-[15%] rotate-45"
          src="./img/Blobb.png"
          alt=""
        />

        <div className="w-full lg:w-1/2 p-4 mb-12 lg:ml-auto">
          <Link
            className="block uppercase text-info lg:text-copy flex items-center justify-end lg:justify-start group"
            to="/"
          >
            <LeftArrowIcon />
            <span className="mt-1">Zurück zur Übersicht</span>
          </Link>
        </div>
      </div>
      <Footer absolute={false} />
    </div>
  );
}

export default AboutPage;
