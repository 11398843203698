import { useEffect, useState } from "react";
import { API_BASE_URL } from "../config";

const useQuestionsFetch = (
  questionSet: string,
  interval = 0,
  shuffle = false,
) => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const url = `${API_BASE_URL}/questions?questionSet=${questionSet}&shuffle=${shuffle}`;

  useEffect(() => {
    let timerId: NodeJS.Timer;

    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(url);
        const json = await response.json();
        setData(json);
        setIsLoading(false);
      } catch (fetchError: any) {
        setError(fetchError);
        setIsLoading(false);
      }
    }

    fetchData();

    if (interval > 0) {
      timerId = setInterval(() => {
        fetchData();
      }, interval * 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [url, interval]);

  return {
    data,
    error,
    setData,
    isLoading,
  };
};

const useQuestionDetailFetch = (questionId: string) => {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const url = `${API_BASE_URL}/questions/${questionId}`;

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(url);
        const json = await response.json();
        setData(json);
        setIsLoading(false);
      } catch (fetchError: any) {
        setError(fetchError);
        setIsLoading(false);
      }
    }

    fetchData();
  }, [url]);

  return {
    data,
    error,
    setData,
    isLoading,
  };
};

const useSecondaryQuestionDetailFetch = (
  questionSet: string,
  questionText: string,
) => {
  console.log("called");
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const params = new URLSearchParams({
    question_set: questionSet,
    question_text: questionText,
  });

  const url = `${API_BASE_URL}/question?${params}`;
  console.log(url);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(url);
        const json = await response.json();
        setData(json);
        setIsLoading(false);
      } catch (fetchError: any) {
        setError(fetchError);
        setIsLoading(false);
      }
    }

    fetchData();
  }, [url]);

  return {
    data,
    error,
    setData,
    isLoading,
  };
};

const useAnswerRequest = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const url = `${API_BASE_URL}/answers`;

  const sendPostRequest = async (postData: any) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Something went wrong!");
      }

      setData(responseData);
    } catch (postError: any) {
      setError(postError.message || "Something went wrong!");
    }
    setIsLoading(false);
  };

  return {
    sendPostRequest,
    data,
    error,
    isLoading,
  };
};

export {
  useQuestionsFetch,
  useAnswerRequest,
  useQuestionDetailFetch,
  useSecondaryQuestionDetailFetch,
};
