import React from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  const isAboutPage = location.pathname === "/about";
  if (isAboutPage) return null;
  return (
    <header className="w-full flex flex-col p-2 z-20">
      <Link className="hover:text-black" to="/">
        <div className="flex flex-col w-full">
          <div className="text-right text-info md:text-copy">SIND</div>
          <div className="text-info md:text-copy">GLEICH</div>
          <div className="text-info text-right md:text-copy pr-[25%]">DA</div>
        </div>
      </Link>
    </header>
  );
}

export default Header;
