import React from "react";

type QuestionDetailResultBarProps = {
  questionSet: string;
  answerCount: number;
};

function QuestionDetailResultBar({
  questionSet,
  answerCount,
}: QuestionDetailResultBarProps) {
  const getLabelForSet = (questionSet: string) => {
    switch (questionSet) {
      case "no-kids-2023":
        return "Partnerschaft ohne Kind, Jahr 2023";
      case "kids-2023":
        return "Partnerschaft mit Kind, Jahr 2023";
      case "no-kids-2024":
        return "Partnerschaft ohne Kind, Jahr 2024";
      case "kids-2024":
        return "Partnerschaft mit Kind, Jahr 2024";
      default:
        return "Unbekannt";
    }
  };

  return (
    <div className="flex justify-between items-center text-info md:text-copy">
      <span>{getLabelForSet(questionSet)}</span>
      <span className="mb-1 md:mb-3 min-w-[90px] break-none ml-auto uppercase">
        {`${answerCount} ${answerCount === 1 ? "Antwort" : "Antworten"}`}
      </span>
    </div>
  );
}

export default QuestionDetailResultBar;
