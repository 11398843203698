import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  useQuestionDetailFetch,
  useSecondaryQuestionDetailFetch,
} from "../../hooks/useBackend";
import Footer from "../Footer";
import LeftArrowIcon from "../LeftArrowIcon";
import ResultPercentages from "../ResultPercentages";
import QuestionDetailResultBar from "../QuestionDetailResultBar";
import { Answer } from "../../model/Answer";

const calculatePercentages = (answers: Answer[]) => {
  const filteredAnswers = answers.flatMap((a: Answer) => {
    if (a.answer === "Beide") {
      return ["Mann", "Frau"];
    }
    return a.answer;
  });

  const maleVotes = filteredAnswers.filter(
    (answer: string) => answer === "Mann",
  ).length;
  const malePercentage = Math.round((maleVotes / filteredAnswers.length) * 100);
  const femalePercentage = 100 - malePercentage;

  return { malePercentage, femalePercentage };
};

function QuestionDetailPage() {
  const { questionId } = useParams();
  if (!questionId) {
    return (
      <div className="text-4xl mt-10 text-center">
        Die Frage konnte nicht gefunden werden.
      </div>
    );
  }

  const questionSetMap = {
    "no-kids-2023": "kids-2023",
    "kids-2023": "no-kids-2023",
    "no-kids-2024": "kids-2024",
    "kids-2024": "no-kids-2024",
  } as Record<string, string>;

  const { data: question } = useQuestionDetailFetch(questionId);
  const { questionSet, questionText } = question;

  const secondarySet = questionSetMap[questionSet] || "no-kids-2023";
  const { data: secondaryQuestion } = useSecondaryQuestionDetailFetch(
    secondarySet,
    questionText,
  );

  if (!question.answers) {
    return <div className="text-4xl mt-10 text-center">Loading...</div>;
  }

  let { malePercentage, femalePercentage } = calculatePercentages(
    question.answers,
  );

  if (question?.answers.length === 0) {
    malePercentage = 0;
    femalePercentage = 0;
  }

  const getSecondaryPercentages = () => {
    if (!secondaryQuestion.answers || secondaryQuestion.answers.length <= 0)
      return null;
    const {
      malePercentage: secondaryMalePercentage,
      femalePercentage: seondaryFemalePercentage,
    } = calculatePercentages(secondaryQuestion.answers);

    return (
      <>
        <ResultPercentages
          isSecondary
          malePercentage={secondaryMalePercentage}
          femalePercentage={seondaryFemalePercentage}
        />
        <QuestionDetailResultBar
          questionSet={secondaryQuestion.questionSet}
          answerCount={secondaryQuestion.answers.length}
        />
      </>
    );
  };

  return (
    <>
      <div className="pt-6 px-2 flex flex-col h-full pb-8 2xl:pb-8">
        <div className="flex items-center justify-end">
          <Link
            className="uppercase flex items-center text-info mb-4 md:mb-4 group"
            to="/"
          >
            <LeftArrowIcon />
            <span className="mt-1">Zurück zur Übersicht</span>
          </Link>
        </div>

        <h1 className="text-h2 md:text-h1 pr-4">{question?.questionText}</h1>
        <QuestionDetailResultBar
          questionSet={question.questionSet}
          answerCount={question.answers.length}
        />
        <ResultPercentages
          isSecondary={false}
          malePercentage={malePercentage}
          femalePercentage={femalePercentage}
        />
        {getSecondaryPercentages()}
      </div>
      <Footer absolute={false} />
    </>
  );
}

export default QuestionDetailPage;
