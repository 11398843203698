import React from "react";
import { Link } from "react-router-dom";
import RightArrowIcon from "./RightArrowIcon";

function NoMoreQuestionsNotice() {
  return (
    <div className="p-4 md:p-0 pr-20  md:flex border md:border-0 rounded-xl h-full">
      <h4 className="text-h3 md:pr-20 md:text-h2 xl:text-h1 mb-6 xl:w-1/2 2xl:w-auto">
        Du hast alle Fragen beantwortet.
      </h4>
      <div>
        <h4 className="text-h3 md:text-h1 mb-8 md:mb-20 xl:mb-60">Danke!</h4>
        <p className="text-copy md:text-h3 xl:text-h2 md:mb-12">
          Schau dir das Gesamtergebnis in der Übersicht an.
        </p>
        <Link
          className="uppercase group align-start text-h2 xl:text-h1 md:flex hidden"
          to="/"
        >
          <span>Zur Übersicht</span>
          <RightArrowIcon size="lg" />
        </Link>
      </div>
    </div>
  );
}

export default NoMoreQuestionsNotice;
