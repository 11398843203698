import React from "react";
import { Link } from "react-router-dom";
import QuestionResultGrid from "../QuestionResultGrid";
import Footer from "../Footer";
import RightArrowIcon from "../RightArrowIcon";

function HomePage() {
  return (
    <>
      <div className="grid grid-cols-5 xl:grid-cols-9 xl:gap-2 ml-auto my-6 pr-4 xl:px-2">
        <div className="col-span-4 col-start-2 xl:col-start-6">
          <h2 className="text-copy md:text-h3 mb-8">
            WIR MÜSSEN sammelt Daten zur Verteilung von unbezahlter Arbeit und
            visualisiert den Gender Bias in Partnerschaften. Das Exponat ist
            Teil der Intervention SIND GLEICH DA. Diese wurde von 6. - 11. März
            2023 erstmalig ausgestellt.
          </h2>
          <div className="flex xl:hidden">
            <Link
              className="flex items-center text-copy uppercase group mt-2"
              to="/poll"
            >
              <span className="mt-1 mr-1 md:mr-0">Zur Abstimmung</span>
              <RightArrowIcon size="md" />
            </Link>
          </div>
        </div>
      </div>
      <div className="pt-4 min-h-full justify-between flex flex-col items-center">
        <QuestionResultGrid />

        <div className="flex flex-col items-center md:items-end relative w-full mt-4 md:mt-8 overflow-hidden p-2 pb-28 md:pb-16">
          <div className="grid grid-cols-1 md:grid-cols-5 xl:grid-cols-9 xl:gap-2 items-center w-full justify-center">
            <Link
              className="md:col-start-3 col-span-full xl:col-start-6 text-copy md:text-h3 uppercase group mt-2 flex items-center mx-auto md:mx-0"
              to="/about"
            >
              <span className="mt-2 mr-2 inline-block">
                Über sind gleich da
              </span>
              <RightArrowIcon size="md" />
            </Link>
          </div>

          <img
            className="lg:inline-block pointer-events-none top-[50%] z-0 lg:-top-10 -left-1/3 absolute max-h-[1100px] lg:-left-[30%]"
            src="./img/Blobb.png"
            alt="Blobb-Illustration"
          />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default HomePage;
