import React from "react";
import {
  motion, MotionValue,
} from "framer-motion";

function CardArrowRight({ color } : { color: MotionValue<string> }) {
  return (
    <motion.svg className="h-6 ml-4" style={{ fill: color }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.159" height="20" viewBox="0 0 23.159 20">
      <defs>
        <clipPath id="clip-path">
          <path d="M0-71.773H20V-94.932H0Z" transform="translate(0 94.932)" />
        </clipPath>
      </defs>
      <g transform="translate(-71.773) rotate(90)">
        <g transform="translate(0 -94.932)" clipPath="url(#clip-path)">
          <g transform="translate(0 0)">
            <path d="M-61.984-41.915-71.929-55.44h-.109l-9.945,13.525h1.475l5.082-6.885c2.4-3.224,4.481-3.224,6.885,0l5.082,6.885Z" transform="translate(81.984 55.44)" />
          </g>
        </g>
        <g transform="translate(9.345 -91.212)">
          <path d="M0,0,.028,19.438H1.31L1.283,0Z" />
        </g>
      </g>
    </motion.svg>

  );
}

export default CardArrowRight;
