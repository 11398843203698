import React from "react";

type AnswerButtonProps = {
  label: string;
  onClick: () => void;
};
function AnswerButton({ label, onClick }: AnswerButtonProps) {
  let customStyles = "";
  if (label === "Frau") {
    customStyles = "hover:bg-pink";
  } else if (label === "Mann") {
    customStyles = "hover:bg-orange";
  } else {
    customStyles = "hover:bg-mainGray";
  }
  return (
    <button className={`border transition-all uppercase hover:text-white hover:border-transparent rounded-xl border-black text-h2 h-full grow ${customStyles}`} onClick={onClick}>
      {label}
    </button>
  );
}

export default AnswerButton;
