import React, { useEffect } from "react";
import { LayoutGroup } from "framer-motion";
import ResultGridItem from "./ResultGridItem";
import GridControlBar from "./GridControlBar";
import { Question } from "../model/Question";
import { useQuestionsFetch } from "../hooks/useBackend";
import { Answer } from "../model/Answer";
import RightArrowIcon from "./RightArrowIcon";
import LeftArrowIcon from "./LeftArrowIcon";

function QuestionResultGrid() {
  const [activeFilter, setActiveFilter] = React.useState<string>("kids");
  const [activeYear, setActiveYear] = React.useState<string>("2024");

  const { data: questions1 } = useQuestionsFetch(`kids-${activeYear}`, 4);
  const { data: questions2 } = useQuestionsFetch(`no-kids-${activeYear}`, 4);

  useEffect(() => {
    const filterFromStorage = sessionStorage.getItem("selectedFilter");
    if (filterFromStorage) setActiveFilter(filterFromStorage);
    const yearFromStorage = sessionStorage.getItem("selectedYear");
    if (yearFromStorage) setActiveYear(yearFromStorage);
  }, []);

  const handleFilterChange = (filter: string) => {
    setActiveFilter(filter);
    sessionStorage.setItem("selectedFilter", filter);
  };

  const handleYearChange = (year: string) => {
    setActiveYear(year);
    sessionStorage.setItem("selectedYear", year);
  };

  const count1 = questions1.reduce(
    (acc: number, q: Question) => acc + (q.answers ? q.answers.length : 0),
    0,
  );
  const count2 = questions2.reduce(
    (acc: number, q: Question) => acc + (q.answers ? q.answers.length : 0),
    0,
  );

  const answerCount = count1 + count2;

  const getAnswerPercentage = (answers: Answer[], answer: string): number => {
    if (answers.length === 0) return 0;
    const updatedAnswers = answers.flatMap((a) => {
      if (a.answer === "Beide") {
        return ["Mann", "Frau"];
      }
      return a.answer;
    });

    const answerVotes = updatedAnswers.filter((a) => a === answer).length;
    const totalVotes = updatedAnswers.length;
    return Math.round((answerVotes / totalVotes) * 100);
  };

  const getSortedAndFilteredQuestions = () => {
    let availableQuestions: Question[] = [];
    if (activeFilter === "kids") {
      availableQuestions = questions1;
    } else if (activeFilter === "no-kids") {
      availableQuestions = questions2;
    }

    availableQuestions.sort((a, b) => {
      if (!a.answers || a.answers.length === 0) return 1;
      if (!b.answers || b.answers.length === 0) return -1;
      return (
        getAnswerPercentage(b.answers, "Mann") -
        getAnswerPercentage(a.answers, "Mann")
      );
    });

    return availableQuestions;
  };

  return (
    <>
      <GridControlBar
        activeFilter={activeFilter}
        setActiveFilter={handleFilterChange}
        answerCount={answerCount}
        activeYear={activeYear}
        setActiveYear={handleYearChange}
      />
      <div className="hidden md:flex items-center justify-between w-full px-2 mb-2 mt-8 text-info">
        <p className="flex items-center uppercase">
          <span className="inline-block mt-1">Männer müssen</span>
          <RightArrowIcon size={"sm"} />
        </p>
        <p className="flex items-center uppercase">
          <LeftArrowIcon size="sm" />
          <span className="inline-block mt-1">Frauen müssen</span>
        </p>
      </div>
      <div className="p-2 gap-0.5 md:gap-2 columns-5 xl:columns-9 w-full">
        <LayoutGroup>
          {getSortedAndFilteredQuestions().map((question: Question) => (
            <ResultGridItem
              key={question.id}
              id={question.id}
              text={question.questionText}
              answers={question.answers}
            />
          ))}
        </LayoutGroup>
      </div>
    </>
  );
}

export default QuestionResultGrid;
