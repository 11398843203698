import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Answer } from "../model/Answer";

type ResultGridItemProps = {
  id: string;
  text: string;
  answers: Answer[];
};

const getOpacityFromPercentage = (percentage: number) => {
  if (percentage > 70.1) return 1;
  if (percentage > 60.1) return 0.8;
  if (percentage > 50.1) return 0.6;
  return 0.4;
};

function ResultGridItem({ id, text, answers }: ResultGridItemProps) {
  const navigate = useNavigate();
  let backgroundColor = "white";
  let color = "black";
  let borderColor = "#333";
  let opacity = 0.33;

  if (answers.length > 0) {
    const updatedAnswers = answers.flatMap((a) => {
      if (a.answer === "Beide") {
        return ["Mann", "Frau"];
      }
      return a.answer;
    });

    const maleVotes = updatedAnswers.filter(
      (answer) => answer === "Mann",
    ).length;

    const malePercentage = Math.round(
      (maleVotes / updatedAnswers.length) * 100,
    );
    const femalePercentage = 100 - malePercentage;

    if (malePercentage !== femalePercentage) {
      color = "white";
      borderColor = "transparent";
      opacity = 1;
    }

    if (malePercentage > femalePercentage) {
      backgroundColor = `rgba(255,153,36,${getOpacityFromPercentage(malePercentage)})`;
    } else if (femalePercentage > malePercentage) {
      backgroundColor = `rgba(235,96,159,${getOpacityFromPercentage(femalePercentage)})`;
    }
  }

  return (
    <div className="break-inside-avoid-column">
      <motion.div
        onClick={() => navigate(`/questions/${id}`)}
        layout
        layoutId={text}
        className="cursor-pointer block break-inside-avoid-column border p-4 h-12 md:h-36 rounded-md md:rounded-xl border-1 "
        style={{
          backgroundColor,
          color,
          opacity,
          borderColor,
        }}
      >
        <span className="hidden md:block text-copy break-words">{text}</span>
      </motion.div>
      <div className="h-0.5 md:h-2" />
    </div>
  );
}

export default ResultGridItem;
