import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./components/pages/HomePage";
import PollPage from "./components/pages/PollPage";
import QuestionDetailPage from "./components/pages/QuestionDetailPage";
import AboutPage from "./components/pages/AboutPage";
import ImprintPage from "./components/pages/ImprintPage";

const routerBasePath = process.env.REACT_APP_ROUTER_BASE || "";

function App() {
  return (
    <div id="app" className="App overflow-x-hidden h-full md:h-screen flex flex-col">
      <BrowserRouter basename={routerBasePath}>
        <Header />
        <div id="content" className="h-full">
          <Routes>
            <Route path="*" element={<HomePage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="poll" element={<PollPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="imprint" element={<ImprintPage />} />
            <Route path="questions/:questionId" element={<QuestionDetailPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
