import React from "react";
import { Link } from "react-router-dom";
import FilterButton from "./FilterButton";
import RightArrowIcon from "./RightArrowIcon";

type GridControlBarProps = {
  activeFilter: string;
  setActiveFilter: (filter: string) => void;
  activeYear: string;
  setActiveYear: (year: string) => void;
  answerCount: number;
};

const toggleFullscreen = () => {
  const elem = document.getElementById("root");
  if (!document.fullscreenElement) {
    elem?.requestFullscreen();
  } else {
    document.exitFullscreen();
  }
};

function GridControlBar({
  activeFilter,
  setActiveFilter,
  activeYear,
  setActiveYear,
  answerCount,
}: GridControlBarProps) {
  return (
    <div className="flex items-end 2xl:items-center w-full gap-4 px-2 md:px-4 mb-4 flex-wrap text-black xl:grid-cols-9 xl:grid">
      <div className="flex gap-2 items-center flex-wrap xl:col-span-3">
        <p className="md:block mr-2 md:mr-4 min-w-[3rem] shrink-0">FILTER</p>
        <div className="flex gap-2 items-center">
          <FilterButton
            label="Partnerschaft mit Kind"
            filter="kids"
            activeFilter={activeFilter}
            onClick={setActiveFilter}
          />
          <FilterButton
            label="Partnerschaft ohne Kind"
            filter="no-kids"
            activeFilter={activeFilter}
            onClick={setActiveFilter}
          />
        </div>
      </div>
      <div className="flex gap-2 items-center flex-wrap xl:col-span-2">
        <p className="md:block mr-2 min-w-[3rem] shrink-0">
          JAHR
          <span className="hidden 2xl:inline"> DER ABSTIMMUNG</span>
        </p>
        <div className="flex gap-2 items-center">
          <FilterButton
            label="2023"
            filter="2023"
            activeFilter={activeYear}
            onClick={setActiveYear}
          />
          <FilterButton
            label="2024"
            filter="2024"
            activeFilter={activeYear}
            onClick={setActiveYear}
          />
        </div>
      </div>
      <div className="xl:col-start-6 xl:col-span-4 flex items-center justify-between ml-auto xl:m-0">
        <Link
          className="hidden xl:flex items-center text-h3 uppercase group"
          to="/poll"
        >
          <span className="mt-1 mr-2">Zur Abstimmung</span>
          <RightArrowIcon size="md" />
        </Link>
        <button
          className="ml-auto uppercase select-none text-info md:text-copy"
          onClick={() => toggleFullscreen()}
        >
          {`${answerCount.toLocaleString()} ${answerCount === 1 ? "Antwort" : "Antworten"}`}
        </button>
      </div>
    </div>
  );
}

export default GridControlBar;
