import React from "react";
import MainButton from "./MainButton";
import RightArrowIcon from "./RightArrowIcon";
import Footer from "./Footer";

type DisclaimerProps = {
  hideDisclaimer: () => void;
};

function Disclaimer({ hideDisclaimer }: DisclaimerProps) {
  return (
    <>
      <div className="px-4 md:px-0 flex flex-col md:flex-row flex-1 md:pt-12">
        <div className="w-1/2 relative overflow-hidden">
          <h1 className="text-h2 xl:text-h1 mb-6 sm:mb-8 md:px-4">
            Disclaimer:
          </h1>
          <img
            src="./img/Blobb.png"
            alt="Blobb Illustration"
            className="hidden md:block absolute top-[50%] right-[25%]"
          />
        </div>

        <div className="border mt-auto md:border-none p-4 pb-0 rounded-lg h-full mb-2 md:w-1/2 max-w-3xl pr-8 md:flex flex-col">
          <h1 className="text-h2 xl:text-h1 mb-4 sm:mb-8">Binär-Alert!</h1>
          <div className="text-copy md:mt-auto md:pb-40">
            <p className="mb-3 md:mb-6">
              Die Ungleichberechtigung der Geschlechter besteht unter anderem
              daher, dass unsere Gesellschaft das Binäre System seit Jahrzehnten
              reproduziert. SIND GLEICH DA wird aus dem Blickwinkel betrachtet,
              aus dem das Problem entsteht.
            </p>
            <p className="mb-3 md:mb-12">
              Das Exponat WIR MÜSSEN versucht das „Wir“, hinter dem es sich gut
              verstecken lässt, zu trennen. WIR MÜSSEN bezieht sich deshalb auf
              dein gelesenes Geschlecht.
            </p>

            <div className="hidden md:text-h2 xl:text-h1 md:flex hover:text-pink group">
              <button onClick={() => hideDisclaimer()}>OK, START</button>
              <RightArrowIcon size="xl" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto px-4 md:hidden">
        <MainButton
          isBig
          label="Danke für die Info"
          onClick={() => hideDisclaimer()}
        />
      </div>
      <div className="hidden md:block">
        <Footer absolute />
      </div>
    </>
  );
}

export default Disclaimer;
