import React, { useState } from "react";
import { QuestionnaireContainerProps } from "../props/QuestionnaireContainerProps";
import { useAnswerRequest, useQuestionsFetch } from "../hooks/useBackend";
import Questionnaire from "./Questionnaire";
import { Question } from "../model/Question";

function QuestionnaireContainer({
  questionSet,
  userResults,
  updateResults,
  finishQuestionnaire,
}: QuestionnaireContainerProps) {
  const {
    data: questions,
    setData: setQuestions,
    isLoading,
  } = useQuestionsFetch(questionSet, 0, true);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const { sendPostRequest } = useAnswerRequest();

  const onAnswered = (questionId: string, answer: string) => {
    setQuestions(
      questions.filter((question: Question) => question.id !== questionId),
    );
    sendPostRequest({ questionId, answer })
      .then(() => {
        const localStorageKey = `question-${questionId}`;
        setQuestions(
          questions.filter((question: any) => question.id !== questionId),
        );
        localStorage.setItem(localStorageKey, "true");
        setQuestionsAnswered(questionsAnswered + 1);
        if (answer === "Mann") {
          updateResults({ ...userResults, male: userResults.male + 1 });
        } else if (answer === "Frau") {
          updateResults({ ...userResults, female: userResults.female + 1 });
        } else if (answer === "Beide") {
          updateResults({
            female: userResults.female + 1,
            male: userResults.male + 1,
          });
        }

        if (questionsAnswered + 1 === 10) {
          finishQuestionnaire();
        }
      })
      .catch((error) => {
        console.log(error);
        setQuestionsAnswered(questionsAnswered);
      });
  };

  const onSkip = () => {
    const filteredQuestions = questions.filter((question: Question) => {
      const localStorageKey = `question-${question.id}`;
      const localStorageValue = localStorage.getItem(localStorageKey);
      return localStorageValue === null;
    });
    const questionId = filteredQuestions[0].id;
    setQuestions(
      filteredQuestions.filter((question: any) => question.id !== questionId),
    );
  };

  return (
    <div className="h-full relative flex flex-col">
      <Questionnaire
        loading={isLoading}
        questions={questions.slice(0).reverse()}
        onAnswered={onAnswered}
        onSkip={onSkip}
        questionsAnswered={questionsAnswered}
      />
    </div>
  );
}

export default QuestionnaireContainer;
