import React from "react";
import { Link, useNavigate } from "react-router-dom";
import QuestionCard from "./QuestionCard";
import MainButton from "./MainButton";
import NoMoreQuestionsNotice from "./NoMoreQuestionsNotice";
import { Question } from "../model/Question";
import DesktopQuestionCards from "./DesktopQuestionCards";
import LeftArrowIcon from "./LeftArrowIcon";

type QuestionnaireProps = {
  loading: boolean;
  questions: any;
  onAnswered: (questionId: string, answer: string) => void;
  onSkip: () => void;
  questionsAnswered: number;
};

function Questionnaire({
  loading,
  questions,
  onAnswered,
  onSkip,
  questionsAnswered,
}: QuestionnaireProps) {
  const navigate = useNavigate();
  const filteredQuestions = questions.filter((question: Question) => {
    const localStorageKey = `question-${question.id}`;
    const localStorageValue = localStorage.getItem(localStorageKey);
    return localStorageValue === null;
  });

  // slice the last 2 cards
  const shownQuestions = filteredQuestions.slice(-2);
  const noMoreQuestions = filteredQuestions.length <= 0;

  return (
    <>
      <div className="flex items-start px-4 justify-between mb-4 md:mb-6 ">
        <h4 className={`text-h2 ${noMoreQuestions ? "md:hidden" : ""} `}>
          Wer macht&apos;s öfter?
        </h4>
        <span className="text-copy pl-4 self-end md:hidden">
          {questionsAnswered + 1}/10
        </span>

        {!noMoreQuestions && (
          <Link to="/" className="hidden md:flex items-center group uppercase">
            <LeftArrowIcon />
            <span className="mt-1">Zur Übersicht</span>
          </Link>
        )}
      </div>
      <div className="h-full relative px-4 mb-4 hidden md:block">
        <DesktopQuestionCards
          questions={filteredQuestions}
          onAnswered={onAnswered}
          questionsAnswered={questionsAnswered}
        />
      </div>
      <div className="h-full relative mx-4 mb-2 block md:hidden">
        {!loading && <NoMoreQuestionsNotice />}
        {shownQuestions.map((question: Question) => (
          <QuestionCard
            onAnswered={(answer) => onAnswered(question.id, answer)}
            key={question.id}
            text={question.questionText}
          />
        ))}
      </div>
      <div className="flex md:hidden px-4 gap-2">
        {!noMoreQuestions && (
          <MainButton label="Nicht zutreffend" onClick={() => onSkip()} />
        )}
        <MainButton
          label="Zur Übersicht"
          onClick={() => navigate("/")}
          isBig={noMoreQuestions}
        />
      </div>
      {!noMoreQuestions && (
        <button
          className="py-6 mx-4 hover:text-pink hover:border-pink transition-all text-center hidden md:flex flex-col justify-center items-center border uppercase rounded-xl border-black text-h2 h-1/3 grow"
          onClick={() => onSkip()}
        >
          <span>Nicht Zutreffend</span>
        </button>
      )}
    </>
  );
}

export default Questionnaire;
