import React from "react";
import {
  motion, useAnimationControls, useMotionValue, useTransform,
} from "framer-motion";
import CardArrowLeft from "./CardArrowLeft";
import CardArrowRight from "./CardArrowRight";

function QuestionCard({
  text, onAnswered,
}: { text: string, onAnswered: (answer:string) => void }) {
  const motionValueX = useMotionValue(0);
  const motionValueY = useMotionValue(0);
  const rotateValue = useTransform(motionValueX, [-500, 0, 500], [-15, 0, 15]);
  const opacityValueX = useTransform(
    motionValueX,
    [-1000, -500, 0, 500, 1000],
    [0, 0.95, 1, 0.95, 0],
  );
  const opacityValueY = useTransform(motionValueY, [-150, 0], [0, 1]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const opacityValueLabel1 = useTransform(motionValueX, [-200, 0, 200], [1, 0, 1]);

  const animateControls = useAnimationControls();
  const backgroundColorX = useTransform(motionValueX, [-75, 0, 75], ["#ff9924", "#e5e5e5", "#eb609f"]);
  const fontColorX = useTransform(motionValueX, [-50, 0, 50], ["#ff9924", "#000", "#eb609f"]);
  const fontOpacityLeft = useTransform(motionValueX, [0, 50], [1, 0]);
  const fontOpacityRight = useTransform(motionValueX, [-50, 0], [0, 1]);
  const fontOpacityUp = useTransform(motionValueY, [-500, 0], [0, 1]);
  const fontOpacityLeftRight = useTransform(motionValueX, [-50, 0, 50], [0, 1, 0]);
  const fontColorY = useTransform(motionValueX, [-50, 0, 50], ["#fff", "#000", "#fff"]);
  const backgroundColorX2 = useTransform(motionValueY, [-350, 0], ["rgba(210,210,210,0.9)", "rgba(0, 0, 0, 0)"]);

  const onCardDragEnd = () => {
    if (motionValueY.get() < -125 && motionValueX.get() < 150 && motionValueX.get() > -150) {
      animateControls.start({ y: -1000 }).then(() => {
        onAnswered("Beide");
      });
    } else if (motionValueY.get() < -200) {
      animateControls.start({ y: -1000 }).then(() => {
        onAnswered("Beide");
      });
    } else if (motionValueX.get() > 100) {
      animateControls.start({ x: 1000 }).then(() => { onAnswered("Frau"); });
    } else if (motionValueX.get() < -100) {
      animateControls.start({ x: -1000 }).then(() => { onAnswered("Mann"); });
    } else {
      animateControls.start({ x: 0, y: 0 });
    }
  };

  return (
    <>
      <motion.div
        className="border rounded-xl border-1 p-4 w-full h-full absolute top-0"
        style={{
          backgroundColor: backgroundColorX, opacity: opacityValueX,
        }}
      />
      <motion.div
        className="border rounded-xl border-1 p-4 w-full h-full absolute top-0"
        style={{
          backgroundColor: backgroundColorX2,
        }}
      />
      <motion.div
        className="border rounded-xl border-1 p-4 w-full h-full bg-white absolute top-0"
        drag
        animate={animateControls}
        style={{
          x: motionValueX, y: motionValueY, rotate: rotateValue,
        }}
                /* dragConstraints={{left: -1000, right: 1000}} */
        onDragEnd={onCardDragEnd}
      >
        <h2 className="h-20 max-w-lg m-auto text-h2">{text}</h2>
        <motion.div
          className="absolute flex flex-col items-center bottom-16 text-2xl left-0 right-0"
          style={{ opacity: fontOpacityUp, color: fontColorY }}
        >
          <motion.img style={{ opacity: fontOpacityLeftRight }} className="h-8 mb-4" src="./img/arrow_straight_up.svg" alt="" />
          <span>Beide</span>
        </motion.div>
        <motion.div style={{ opacity: opacityValueY }}>
          <motion.div
            className="absolute flex items-center left-8 bottom-4 text-2xl"
            style={{ color: fontColorX, opacity: fontOpacityLeft }}
          >
            <CardArrowLeft color={fontColorX} />
            <span className="mt-1">Mann</span>
          </motion.div>
          <motion.div
            className="absolute flex items-center right-8 bottom-4 text-2xl"
            style={{ color: fontColorX, opacity: fontOpacityRight }}
          >
            <span className="mt-1">Frau</span>
            <CardArrowRight color={fontColorX} />
          </motion.div>
        </motion.div>
      </motion.div>

    </>
  );
}

export default QuestionCard;
