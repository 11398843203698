import React from "react";

function ScrollImage() {
  return (
    <div
      id="scrollContainer"
      className="w-full pl-[10%] lg:pl-[20%] gap-2 grid grid-cols-2 lg:grid-cols-4 mb-20 md:mb-40 z-10"
    >
      <img
        id="scrollImage"
        src="./img/about08.jpeg"
        alt="Behind the Scenes 01"
      />
      <img
        id="scrollImage"
        className=""
        src="./img/about09.jpeg"
        alt="Behind the Scenes 01"
      />
      <img
        id="scrollImage"
        className=""
        src="./img/about10.jpeg"
        alt="Behind the Scenes 01"
      />
      <img
        id="scrollImage"
        className=""
        src="./img/about11.jpeg"
        alt="Behind the Scenes 01"
      />
    </div>
  );
}

export default ScrollImage;
