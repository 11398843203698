import React from "react";

type DesktopSetChoiceProps = {
  getQuestionSet: (withChildren: boolean) => void;
};
function DesktopSetChoice({ getQuestionSet }: DesktopSetChoiceProps) {
  return (
    <>
      <button className="h-full grow relative border-black border rounded-xl text-left p-2 group hover:bg-orange hover:border-transparent transition-all" onClick={() => getQuestionSet(true)}>
        <span className="absolute top-2 left-2 text-h2 group-hover:text-white">... mit Kind</span>
      </button>
      <button className="h-full grow relative border-black border rounded-xl text-left p-2 group hover:bg-pink hover:border-transparent transition-all" onClick={() => getQuestionSet(false)}>
        <span className="absolute top-2 left-2 group-hover:text-white text-h2">... ohne Kind</span>
      </button>
    </>
  );
}

export default DesktopSetChoice;
