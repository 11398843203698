import React, { useEffect } from "react";
import QuestionSetChoice from "../QuestionSetChoice";
import QuestionnaireContainer from "../QuestionnaireContainer";
import Disclaimer from "../Disclaimer";
import PollResultContainer from "../PollResultContainer";

enum PollStages {
  DISCLAIMER,
  SET_CHOICE,
  RESULTS,
  QUESTIONNAIRE,
}

type UserResults = {
  male: number;
  female: number;
};

function PollPage() {
  const activeYear = 2024;
  const [chosenSet, setChosenSet] = React.useState<string>("");
  const [pollStage, setPollStage] = React.useState<PollStages>(
    PollStages.DISCLAIMER,
  );

  const [userResults, setUserResults] = React.useState<UserResults>({
    male: 0,
    female: 0,
  });

  const getPercentage = (results: UserResults) => {
    console.log(results);
    const total = results.male + results.female;
    const malePercentage = Math.round((results.male / total) * 100);

    const femalePercentage = Math.round((results.female / total) * 100);

    return { malePercentage, femalePercentage };
  };

  useEffect(() => {
    document.querySelector("html")?.classList.add("no-overscroll");
    document.querySelector("body")?.classList.add("no-overscroll");
    return () => {
      document.querySelector("html")?.classList.remove("no-overscroll");
      document.querySelector("body")?.classList.remove("no-overscroll");
    };
  }, []);

  const onFinish = () => {
    setPollStage(PollStages.RESULTS);
  };

  const onGetQuestionSet = (withKids: boolean) => {
    setChosenSet(withKids ? `kids-${activeYear}` : `no-kids-${activeYear}`);
    setPollStage(PollStages.QUESTIONNAIRE);
  };

  return (
    <div
      className={`max-w-lg md:max-w-none m-auto md:touch-none h-full flex flex-col pt-2 sm:pt-4 pb-4 ${pollStage === PollStages.SET_CHOICE ? "overflow-hidden" : ""}`}
    >
      {pollStage === PollStages.DISCLAIMER && (
        <Disclaimer
          hideDisclaimer={() => setPollStage(PollStages.SET_CHOICE)}
        />
      )}
      {pollStage === PollStages.SET_CHOICE && (
        <QuestionSetChoice getQuestionSet={onGetQuestionSet} />
      )}
      {pollStage === PollStages.QUESTIONNAIRE && (
        <QuestionnaireContainer
          questionSet={chosenSet}
          userResults={userResults}
          updateResults={setUserResults}
          finishQuestionnaire={onFinish}
        />
      )}
      {pollStage === PollStages.RESULTS && (
        <PollResultContainer
          malePercentage={getPercentage(userResults).malePercentage}
          femalePercentage={getPercentage(userResults).femalePercentage}
        />
      )}
    </div>
  );
}

export default PollPage;
