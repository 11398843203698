import React from "react";

type FilterButtonProps = {
  label: string;
  filter: string;
  activeFilter: string;
  onClick: (filter: string) => void;
};

function FilterButton({
  filter,
  activeFilter,
  onClick,
  label,
}: FilterButtonProps) {
  const isEnabled = activeFilter === filter;
  return (
    <button
      className={`text-info select-none md:text-copy text-left align-center border md:hover:text-pink rounded border-dotted px-2 md:px-4 pt-2 pb-1 ${isEnabled ? "border-pink text-pink" : "border-black"}`}
      onClick={() => onClick(filter)}
    >
      {label}
    </button>
  );
}

export default FilterButton;
