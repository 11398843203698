import React from "react";
import MobileSetChoice from "./MobileSetChoice";
import DesktopSetChoice from "./DesktopSetChoice";

type QuestionSetChoiceProps = {
  getQuestionSet: (withChildren: boolean) => void;
};

function QuestionSetChoice({ getQuestionSet }: QuestionSetChoiceProps) {
  return (
    <>
      <h4 className="text-h2 px-4">Ich bin oder war in einer Partnerschaft...</h4>
      <div className="p-4 h-full relative block md:hidden">
        <MobileSetChoice getQuestionSet={getQuestionSet} />
      </div>
      <div className="p-4 h-full gap-4 hidden md:flex">
        <DesktopSetChoice getQuestionSet={getQuestionSet} />

      </div>
    </>
  );
}

export default QuestionSetChoice;
