import React from "react";

function LeftRightArrowIcon() {
  return (
    <>
      <svg className="group-hover:fill-pink" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.159" height="20" viewBox="0 0 23.159 20">
        <defs>
          <clipPath id="clip-path">
            <path id="Pfad_12" data-name="Pfad 12" d="M0,0H20V23.159H0Z" />
          </clipPath>
        </defs>
        <g id="Gruppe_33" data-name="Gruppe 33" transform="translate(23.159) rotate(90)">
          <g id="Gruppe_11" data-name="Gruppe 11" transform="translate(0 0)" clipPath="url(#clip-path)">
            <g id="Gruppe_10" data-name="Gruppe 10" transform="translate(0 9.634)">
              <path id="Pfad_11" data-name="Pfad 11" d="M20,0,10.055,13.525H9.945L0,0H1.475L6.557,6.885c2.4,3.224,4.481,3.224,6.885,0L18.525,0Z" />
            </g>
          </g>
          <g id="Gruppe_12" data-name="Gruppe 12" transform="translate(9.345)">
            <path id="Pfad_13" data-name="Pfad 13" d="M0,19.438.028,0H1.31L1.283,19.438Z" />
          </g>
        </g>
      </svg>
      <svg className="group-hover:fill-pink" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.159" height="20" viewBox="0 0 23.159 20">
        <defs>
          <clipPath id="clip-path">
            <path id="Pfad_12" data-name="Pfad 12" d="M0-71.773H20V-94.932H0Z" transform="translate(0 94.932)" />
          </clipPath>
        </defs>
        <g id="Gruppe_32" data-name="Gruppe 32" transform="translate(-71.773) rotate(90)">
          <g id="Gruppe_11" data-name="Gruppe 11" transform="translate(0 -94.932)" clipPath="url(#clip-path)">
            <g id="Gruppe_10" data-name="Gruppe 10" transform="translate(0 0)">
              <path id="Pfad_11" data-name="Pfad 11" d="M-61.984-41.915-71.929-55.44h-.109l-9.945,13.525h1.475l5.082-6.885c2.4-3.224,4.481-3.224,6.885,0l5.082,6.885Z" transform="translate(81.984 55.44)" />
            </g>
          </g>
          <g id="Gruppe_12" data-name="Gruppe 12" transform="translate(9.345 -91.212)">
            <path id="Pfad_13" data-name="Pfad 13" d="M0,0,.028,19.438H1.31L1.283,0Z" />
          </g>
        </g>
      </svg>
    </>

  );
}

export default LeftRightArrowIcon;
