import React from "react";

type MainButtonProps = {
  label: string,
  onClick: () => void;
  disabled?: boolean;
  isBig?: boolean;
};

function MainButton({
  label, onClick, disabled = false, isBig = false,
}: MainButtonProps) {
  return (
    <button
      disabled={disabled}
      className={
        `${isBig ? "text-copy" : "text-info"} py-6 h-full select-none uppercase mt-auto px-1 border-1 border w-full rounded-lg`
      }
      value={label}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default MainButton;
