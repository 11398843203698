type AboutVideoProps = {};

export default function AboutVideo({}: AboutVideoProps) {
  return (
    <>
      <iframe
        className="h-[320px] md:h-[480px] xl:h-[620px] 2xl:h-[760px]"
        src="https://player.vimeo.com/video/924926263?h=7ca09f35a4"
        width="auto"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </>
  );
}
