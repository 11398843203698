import React from "react";
import ResultPercentages from "./ResultPercentages";
import MainButton from "./MainButton";
import { Link, useNavigate } from "react-router-dom";
import RightArrowIcon from "./RightArrowIcon";

type PollResultContainerProps = {
  malePercentage: number;
  femalePercentage: number;
};

export default function PollResultContainer({
  malePercentage,
  femalePercentage,
}: PollResultContainerProps) {
  const navigate = useNavigate();

  return (
    <div className="pt-6 px-2 flex flex-col h-full 2xl:pb-8">
      <div className="flex items-center justify-between mb-4">
        <p className="text-copy">
          Danke für deine Teilnahme am Exponat WIR MÜSSEN
        </p>
      </div>
      <p className="text-h2 mb-4">Dein persönliches Ergebnis:</p>
      <ResultPercentages
        isSecondary={false}
        malePercentage={malePercentage}
        femalePercentage={femalePercentage}
        hideGenderLabels
      />
      <div className="flex md:hidden">
        <MainButton
          label="Zur Übersicht"
          onClick={() => navigate("/")}
          isBig={true}
        />
      </div>
      <div className="hidden md:flex justify-end mb-6 pt-4">
        <div className="w-1/2">
          <p className="text-h3 mb-4">
            Einen Gesamtüberblick über alle Abstimungen findest du in der
            Übersicht.
          </p>
          <Link className="uppercase group align-start text-h2" to="/">
            <span>Zur Übersicht</span>
            <RightArrowIcon size="lg" />
          </Link>
        </div>
      </div>
    </div>
  );
}
