import React from "react";
import Footer from "../Footer";

function ImprintPage() {
  return (
    <div className="flex flex-col items-start md:flex-row px-2 md:px-1 pt-8 relative overflow-hidden">
      <div className="w-full md:w-1/2">
        <h1 className="text-h2 md:text-h1 mb-8 md:mb-32">
          Impressum
        </h1>
        <img className="hidden md:inline-block relative -left-24" src="./img/Blobb.png" alt="Blobb" />
      </div>
      <div className="w-full md:w-1/2 text-info md:text-copy pb-80 md:pb-10 overflow-hidden relative">
        <table className="table-auto border-separate border-spacing-y-2 md:border-spacing-y-8">
          <tbody className="align-top">
            <tr className="mb-4">
              <td className="min-w-[150px] break-all md:break-normal">SIND GLEICH DA</td>
              <td className="pl-4 md:pl-12">Masterprojekt Kommunikationsdesign MultiMediaArt</td>
            </tr>
            <tr>
              <td className="min-w-[150px] break-all md:break-normal">FH SALZBURG</td>
              <td className="pl-4 md:pl-12">
                <address className="not-italic">
                  Urstein Süd 3
                  <br />
                  5412 Puch bei Hallein
                  <br />
                  AUSTRIA
                </address>
              </td>
            </tr>
            <tr>
              <td className="min-w-[150px] break-all md:break-normal">KONTAKT</td>
              <td className="pl-4 md:pl-12">
                <a href="mailto:wir@sindgleichda.com">wir@sindgleichda.com</a>
              </td>
            </tr>
            <tr>
              <td className="min-w-[150px] break-all md:break-normal">VERANTWORTLICHE</td>
              <td className="pl-4 md:pl-12">
                Jana Rowenski & Magdalena Jo Umkehrer
              </td>
            </tr>
            <tr>
              <td className="min-w-[150px] break-all md:break-normal">TYPOGRAFIE</td>
              <td className="pl-4 md:pl-12">
                New Edge 666, Charlotte Rhode
              </td>
            </tr>
            <tr>
              <td className="min-w-[150px] break-all md:break-normal">PROJEKTMANAGEMENT</td>
              <td className="pl-4 md:pl-12">
                Patricia Neuhauser
              </td>
            </tr>
            <tr>
              <td className="min-w-[150px] break-all md:break-normal">FOTOGRAFIE</td>
              <td className="pl-4 md:pl-12">
                Lea Kurz, Christoph Platzer
              </td>
            </tr>
            <tr>
              <td className="min-w-[150px] break-all md:break-normal">FILMISCHE DOKUMENTATION</td>
              <td className="pl-4 md:pl-12">
                Constanze Radak
              </td>
            </tr>
            <tr>
              <td className="min-w-[150px] break-all md:break-normal">WEB DEVELOPMENT</td>
              <td className="pl-4 md:pl-12">
                Nicolas Graf
              </td>
            </tr>
            <tr>
              <td className="min-w-[150px] break-all md:break-normal">DANKE FÜR EURE UNTERSTÜTZUNG</td>
              <td className="pl-4 md:pl-12">
                Alina Traun, Annika Braun, Birgitta Maria Umkehrer, Benjamin Lageder,
                Christoph Amort, Christoph Platzer, Constanze Radak, David Vassili Friedmann,
                Denise Hödl, Florian Weiermann, Joshua Fischer, Lea Kurz, Lea Schönleber,
                Michelle Pointinger,
                Moritz Beeking, Nicolas Graf, Michael Thelonious Hamel, Oliver Classen,
                Patricia Neuhauser,
                Paula Nikolussi, Renaldo Rohrmoser, Ricarda Moser, Sophie Rutzinger,
                Sebastian Scholtze,
                Simon Lutz & Tobias Meyer.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <img className="md:hidden max-h-[900px] -left-12 -bottom-12 absolute" src="./img/Blobb.png" alt="Blobb Illustration" />
      <Footer />
    </div>
  );
}

export default ImprintPage;
