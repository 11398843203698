import React from "react";

function RightArrowIcon({
  size = "md",
  white = false,
}: {
  size: "sm" | "md" | "lg" | "xl";
  white?: boolean;
}) {
  const heightClasses = {
    sm: "h-4 md:h-4",
    md: "h-5 xl:h-8",
    lg: "h-5 md:h-10",
    xl: "h-5 md:h-16",
  };

  return (
    <svg
      className={`${heightClasses[size]} ${white ? "fill-white" : ""} ml-1 md:ml-2 inline group-hover:fill-pink`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 23.159 20"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Pfad_12"
            data-name="Pfad 12"
            d="M0-71.773H20V-94.932H0Z"
            transform="translate(0 94.932)"
          />
        </clipPath>
      </defs>
      <g
        id="Gruppe_32"
        data-name="Gruppe 32"
        transform="translate(-71.773) rotate(90)"
      >
        <g
          id="Gruppe_11"
          data-name="Gruppe 11"
          transform="translate(0 -94.932)"
          clipPath="url(#clip-path)"
        >
          <g id="Gruppe_10" data-name="Gruppe 10" transform="translate(0 0)">
            <path
              id="Pfad_11"
              data-name="Pfad 11"
              d="M-61.984-41.915-71.929-55.44h-.109l-9.945,13.525h1.475l5.082-6.885c2.4-3.224,4.481-3.224,6.885,0l5.082,6.885Z"
              transform="translate(81.984 55.44)"
            />
          </g>
        </g>
        <g
          id="Gruppe_12"
          data-name="Gruppe 12"
          transform="translate(9.345 -91.212)"
        >
          <path
            id="Pfad_13"
            data-name="Pfad 13"
            d="M0,0,.028,19.438H1.31L1.283,0Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default RightArrowIcon;
