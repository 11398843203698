import React from "react";
import {
  motion, MotionValue,
} from "framer-motion";

function CardArrowLeft({ color } : { color: MotionValue<string> }) {
  return (
    <motion.svg className="h-6 mr-4" style={{ fill: color }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.159" height="20" viewBox="0 0 23.159 20">
      <defs>
        <clipPath id="clip-path">
          <path d="M0,0H20V23.159H0Z" />
        </clipPath>
      </defs>
      <g transform="translate(23.159) rotate(90)">
        <g transform="translate(0 0)" clipPath="url(#clip-path)">
          <g transform="translate(0 9.634)">
            <path d="M20,0,10.055,13.525H9.945L0,0H1.475L6.557,6.885c2.4,3.224,4.481,3.224,6.885,0L18.525,0Z" />
          </g>
        </g>
        <g transform="translate(9.345)">
          <path d="M0,19.438.028,0H1.31L1.283,19.438Z" />
        </g>
      </g>
    </motion.svg>
  );
}

export default CardArrowLeft;
