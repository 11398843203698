import React from "react";

type LeftArrowIconProps = {
  size?: "sm" | "md" | "lg" | "xl";
  white?: boolean;
};
function LeftArrowIcon({ size = "sm", white }: LeftArrowIconProps) {
  const heightClasses = {
    sm: "h-4 md:h-4",
    md: "h-5 xl:h-5",
    lg: "h-5 md:h-10",
    xl: "h-5 md:h-16",
  };
  return (
    <svg
      className={`${heightClasses[size]} mr-1 md:mr-2 inline group-hover:fill-pink ${white && "fill-white"}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 23.159 20"
    >
      <defs>
        <clipPath id="clip-path">
          <path id="Pfad_12" data-name="Pfad 12" d="M0,0H20V23.159H0Z" />
        </clipPath>
      </defs>
      <g
        id="Gruppe_33"
        data-name="Gruppe 33"
        transform="translate(23.159) rotate(90)"
      >
        <g
          id="Gruppe_11"
          data-name="Gruppe 11"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <g
            id="Gruppe_10"
            data-name="Gruppe 10"
            transform="translate(0 9.634)"
          >
            <path
              id="Pfad_11"
              data-name="Pfad 11"
              d="M20,0,10.055,13.525H9.945L0,0H1.475L6.557,6.885c2.4,3.224,4.481,3.224,6.885,0L18.525,0Z"
            />
          </g>
        </g>
        <g id="Gruppe_12" data-name="Gruppe 12" transform="translate(9.345)">
          <path
            id="Pfad_13"
            data-name="Pfad 13"
            d="M0,19.438.028,0H1.31L1.283,19.438Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default LeftArrowIcon;
