import React from "react";
import { Link } from "react-router-dom";

function Footer({ absolute = true }: { absolute?: boolean }) {
  return (
    <footer
      className={`flex bottom-0 ${absolute ? "absolute" : ""} z-0 justify-between w-full ft-0 right-0 p-2`}
    >
      <Link className="text-base uppercase" to="/imprint">
        Impressum
      </Link>
      <span className="text-base ml-auto">(C) 2024</span>
    </footer>
  );
}

export default Footer;
