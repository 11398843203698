import React from "react";
import {
  motion, useAnimationControls, useMotionValue, useTransform,
} from "framer-motion";
import LeftRightArrowIcon from "./LeftRightArrowIcon";

type MobileSetChoiceProps = {
  getQuestionSet: (withChildren: boolean) => void;
};
function MobileSetChoice({ getQuestionSet }: MobileSetChoiceProps) {
  const motionValue = useMotionValue(0);
  const rotateValue = useTransform(motionValue, [-500, 0, 500], [-15, 0, 15]);
  const backgroundColorX = useTransform(motionValue, [-3000, -50, 0, 50, 3000], ["#fff", "#ff9924", "#fff", "#eb609f", "#fff"]);
  const animateControls = useAnimationControls();

  const fontColorXLeftWhite = useTransform(motionValue, [-50, 0], ["rgba(255, 255, 255, 0.0)", "rgba(255, 255, 255, 1.0)"]);
  const fontColorXRightWhite = useTransform(motionValue, [0, 50], ["rgba(255, 255, 255, 1.0)", "rgba(255, 255, 255, 0.0)"]);

  const fontColorXLeftBlack = useTransform(motionValue, [-50, 0], ["rgba(0, 0, 0, 0.0)", "rgba(0, 0, 0, 1.0)"]);
  const fontColorXRightBlack = useTransform(motionValue, [0, 50], ["rgba(0, 0, 0, 1.0)", "rgba(0, 0, 0, 0.0)"]);

  const onCardDragEnd = () => {
    if (motionValue.get() > 125) {
      animateControls.start({ x: 1000 }).then(() => { getQuestionSet(false); });
    } else if (motionValue.get() < -125) {
      animateControls.start({ x: -1000 }).then(() => { getQuestionSet(true); });
    } else {
      animateControls.start({ x: 0 });
    }
  };

  return (
    <>
      <motion.div
        className="absolute m-4 top-0 left-0 right-0 bottom-0 rounded-xl"
        style={{ backgroundColor: backgroundColorX }}
      >
        <motion.p style={{ color: fontColorXLeftWhite }} className="top-4 absolute left-4 text-white text-h2">... ohne Kind</motion.p>
        <motion.p style={{ color: fontColorXRightWhite }} className="top-4 absolute right-4 text-white text-h2">... mit Kind</motion.p>
      </motion.div>
      <motion.div
        className="border p-4 border-1 relative rounded-xl h-full bg-white mb-0"
        drag="x"
        animate={animateControls}
        style={{
          x: motionValue, rotate: rotateValue,
        }}
        onDragEnd={onCardDragEnd}
      >

        <h2 className="h-20 max-w-lg m-auto text-h2">Swipe!</h2>
        <div
          className="absolute flex items-center flex-col right-0 left-0 bottom-4 text-2xl"
        >
          <div className="flex mb-12">
            <LeftRightArrowIcon />
          </div>
          <div className="flex space-between w-full text-copy">
            <motion.span style={{ color: fontColorXRightBlack }} className="ml-4 uppercase">Mit Kind</motion.span>
            <motion.span style={{ color: fontColorXLeftBlack }} className="ml-auto mr-4 uppercase">Ohne Kind</motion.span>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default MobileSetChoice;
